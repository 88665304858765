div.create-group-button {
    display: flex;
    flex-direction: row;
    width: 232px;
    height: 40px;
    border-radius: 20px;
    background-color: #ffffff;
    cursor: pointer;

    div.icon {
        width: 18px;
        height: 18px;
        margin: 11px 0px 11px 46px;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;

        &.plus-icon {
            background-image: url('../img/icon-plus.png');
        }

        &.link-icon {
            background-image: url('../img/icon-link.png');
        }
    }

    div.text {
        width: 116px;
        height: 18px;
        font-family: Nunito;
        font-size: 13px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        letter-spacing: 1.18px;
        text-align: center;
        color: #18181c;
        margin: 11px 0px 11px 6px;
    }
}

div.invite-button {
    display: flex;
    flex-direction: column;
    width: 70px;
    height: 32px;
    border-radius: 16px;
    background-color: #ffffff;
    cursor: pointer;

    div.text {
        font-family: Nunito;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #18181c;
        margin: 7px 0px 7px 0px;
    }
}

div.copy-invite-link-button {
    display: flex;
    flex-direction: row;

    position: absolute;
    right: 0px;
    width: 174px;
    height: 40px;
    border-radius: 20px;
    background-color: #ffffff;
    cursor: pointer;

    div.icon {
        width: 18px;
        height: 18px;
        margin: 10px 0px 10px 20px;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;

        &.plus-icon {
            background-image: url('../img/icon-plus.png');
        }

        &.link-icon {
            background-image: url('../img/icon-link.png');
        }
    }

    div.text {
        width: 111px;
        height: 18px;
        font-family: Nunito;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #18181c;
        margin: 12px 19px 10px 6px;
    }
}

div.start-button {
    display: flex;
    flex-direction: row;
    width: 163px;
    height: 40px;
    border-radius: 20px;
    background-color: #ffffff;
    cursor: pointer;

    div.text {
        width: 116px;
        height: 18px;
        font-family: Nunito;
        font-size: 13px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        text-align: center;
        color: #18181c;
        margin: 11px 24px 11px 24px;
    }
}
