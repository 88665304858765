$total-width: 247px;
$rank-width: 36px;
$rank-start-spacing: 12px;
$pic-width: 52px;
$pic-name-spacing: 8px;
$name-score-spacing: 8px;
$score-width: 40px;
$score-end-spacing: 20px;
$name-width: $total-width - $rank-width - $rank-start-spacing - $pic-width - $pic-name-spacing - $name-score-spacing -
    $score-width - $score-end-spacing;

div.Leaderboard {
    &.hidden {
        background-color: rgba(0, 0, 0, 0);
        width: 0px;
    }
    display: flex;
    flex-direction: column;
    width: 276px;
    background-color: rgba(0, 0, 0, 0.3);

    .ShowButton {
        position: absolute;
        top: 16px;
        right: 16px;

        width: 70px;
        height: 60px;
        background-image: url(../img/leaderboard-open.png);
        background-size: 100%;
        background-position: center;
    }

    .HideButton {
        position: absolute;
        top: 16px;
        right: 16px;

        width: 29px;
        height: 29px;
        margin-top: 3px;
        margin-right: -3px;
        background-image: url(../img/leaderboard-close.png);
        background-size: 100%;
        background-position: center;
    }

    .ShowButton,
    .HideButton {
        &.Active {
            opacity: 0.5;
        }
    }

    .Header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 21px 11px 4px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.32);

        .First,
        .Last {
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
            font-size: 9px;
            font-weight: 800;
            letter-spacing: 1px;
            margin-bottom: 7px;
            white-space: nowrap;
        }

        .First {
            padding-left: 3px;
            text-align: left;
        }

        .Last {
            text-align: right;
        }
    }

    .Row {
        height: 63px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: -2px 0px;
        .Rank {
            width: $rank-width;
            font-size: 12.6px;
            font-weight: bold;
            letter-spacing: -0.32px;
            margin-left: $rank-start-spacing;
        }

        .Pic {
            width: $pic-width;
            height: $pic-width;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: $pic-width / 2;
            margin-right: $pic-name-spacing;
            background-position: center;
            background-size: 100% 100%;
            transition: background-image 0.5s ease-in;

            &.Online::after {
                content: '';
                display: block;
                border-color: rgb(214, 255, 175);
                border-width: 1px;
                border-style: solid;
                border-radius: 6px;
                width: 10px;
                height: 10px;
                background-color: rgb(109, 212, 0);
            }
        }

        .Name {
            font-size: 11px;
            font-weight: bold;
            letter-spacing: -0.28px;
            width: $name-width;
            margin-right: $name-score-spacing;
        }

        .Score {
            width: $score-width;
            font-size: 19.8px;
            font-weight: bold;
            letter-spacing: -0.5px;
            margin-right: $score-end-spacing;
            text-align: right;
        }
    }

    .Row.Me {
        border-radius: 11px;
        background-color: rgba(0, 0, 0, 0.3);

        .Name {
            font-size: 12.6px;
            letter-spacing: -0.32px;
        }
    }

    .PreviewWindow {
        flex: 0 0 165px;
        margin: 32px 28px 0px 28px;
        width: 220px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        border-radius: 12px;

        .video {
            position: absolute;
            width: 220px;
            height: 165px;
            transform: scaleX(-1);
            border-radius: 12px;
        }

        .noDetectionWarningBackground {
            position: absolute;
            width: 220px;
            height: 165px;
            border-radius: 12px;
            background-color: rgba(0, 0, 0, 0.6);

            &.hidden {
                display: none;
            }
        }

        .noDetectionWarning {
            position: absolute;
            width: 112px;
            height: 138px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;

            &.hidden {
                display: none;
            }

            &.TreadMill {
                background-image: url('../img/icon-cannot-see-you-jog.png');
            }
            &.JumpRope {
                background-image: url('../img/icon-cannot-see-you-jump-rope.png');
            }
            &.JumpingJack {
                background-image: url('../img/icon-cannot-see-you-jumping-jack.png');
            }
        }
    }
}
