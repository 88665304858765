div.SetupPage {
    height: 100%;

    div.background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    div.blurMask {
        filter: blur(30px);
        background-color: black;
        opacity: 0.5;
    }

    div.setup-area {
        &.hidden {
            display: none;
        }

        position: absolute;
        left: 50%;
        top: 50%;
        width: 312px;
        height: auto;
        margin: -116px 0 0 -156px;

        &.camera > .icon {
            width: 188px;
            height: 188px;
            margin-left: 62px;

            background-image: url('../../img/icon-camera.png');
            background-size: cover;
            background-repeat: no-repeat;
        }

        &.inProgress > .icon,
        &.completed > .icon {
            width: 188px;
            height: 188px;
            margin-left: 62px;

            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;

            &.TreadMill {
                background-image: url('../../img/icon-setup-jog.png');
            }
            &.JumpRope {
                background-image: url('../../img/icon-setup-jump-rope.png');
            }
            &.JumpingJack {
                background-image: url('../../img/icon-setup-jumping-jack.png');
            }
        }

        div.text {
            margin-top: 56px;
            width: 312px;
            height: auto;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
            font-family: Nunito;
            font-size: 24.8px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.08;
            letter-spacing: -0.23px;
            text-align: center;
            color: #ffffff;
        }
    }

    div.CameraPreview {
        position: absolute;
        width: 316px; // 292 + 12 * 2
        height: 235px; // 4:3
        top: 56px;
        right: 56px;
        overflow: hidden;

        border-radius: 12px;
        border: solid 5px #ffffff;

        &.goodDetection {
            border: solid 5px #6dd400;
        }

        &.cameraNotReady {
            display: none;
        }

        .AspectFill {
            position: absolute;
            width: 100%;
            padding-bottom: 75%;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    div.countdown-area {
        &.hidden {
            display: none;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        position: absolute;
        width: 100%;
        height: 100%;

        div.text {
            font-family: Nunito;
            font-size: 180px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-align: center;
        }
    }
}
