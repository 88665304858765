.ScreenSizeAlert {
    position: absolute;
    width: 100vw;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 50vh;
    transform: translateY(-50%);

    .Title,
    .Subtitle {
        max-width: 300px;
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
    }

    .Title {
        font-size: 20px;
        font-weight: bold;
    }

    .Subtitle {
        margin-top: 20px;
        font-size: 16px;
    }
}
