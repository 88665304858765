.JumpRopeDashboard {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .BasicMetrics {
        display: flex;
        margin-top: 30px;

        > :not(:first-child) {
            margin-left: 30px;
        }
    }

    > .JumpRopeSecondaryDashboard {
        margin-top: 30px;
        width: 100%;
    }
}
