// The width and height of our video capture and various components.
$landscape-width: 100vw;
$landscape-height: 75vw;
$portrait-width: 75vh;
$portrait-height: 100vh;

div.showDebugFrame div.driver-container canvas.debug {
    display: block;
}

div.driver-container {
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: left;
    align-content: flex-start;
    overflow: hidden;

    canvas {
        position: absolute;
    }
    canvas.debug {
        display: none;
    }

    video {
        position: absolute;
        z-index: -1;
        transform: scaleX(-1);
    }

    &.landscape {
        canvas {
            width: $landscape-width;
            height: $landscape-height;
            top: 50%;
            margin-top: -0.5 * $landscape-height;
        }
        video {
            width: $landscape-width;
            height: $landscape-height;
            top: 50%;
            margin-top: -0.5 * $landscape-height;
        }
        > * .hidden {
            display: none;
        }
    }

    &.portrait {
        canvas {
            width: $portrait-width;
            height: $portrait-height;
            left: 50%;
            margin-left: -0.5 * $portrait-width;
        }
        video {
            width: $landscape-width;
            height: $landscape-height;
            left: 50%;
            margin-left: -0.5 * $portrait-width;
        }
    }

    &.preview {
        canvas,
        video {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
