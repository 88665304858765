.SideBarProfilePanelLayer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    .Panel {
        padding: 16px 16px 0px 16px;
        width: 239px;
        background-color: #194054;
        border-radius: 8px;
        display: flex;
        flex-direction: column;

        .PreviewContainer {
            display: flex;
            height: 44px;
            padding-bottom: 15px;
            border-bottom: solid 0.5px rgba(255, 255, 255, 0.3);

            .SideBarProfileButton {
                margin-bottom: unset;
            }

            .PreviewTextContainer {
                margin-left: 12px;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .NameLabel {
                    font-family: Nunito;
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                }

                .OnlineLabel {
                    font-family: Nunito;
                    font-size: 10px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #6dd400;
                }
            }
        }

        .EditProfileRow {
            height: 46.5px;
            display: flex;
            align-items: center;

            .Icon {
                height: 16px;
                width: 16px;
                background-image: url('../img/icon-edit.png');
                background-size: contain;
            }

            .Text {
                margin-left: 7px;
                font-family: Nunito;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #ffffff;
            }
        }
    }
}
