div.group-home {
    > * .hidden {
        display: none;
    }

    div.home-layer {
        position: absolute;
        top: 0;
        left: 120px;
        z-index: 1;
        width: calc(100vw - 120px);
        min-width: 778px;
        height: 100%;
    }

    > * .group-selection-layer {
        position: absolute;
        z-index: 11;
        width: 287px;
        top: 57px;
        right: 63px;
        height: 50vh;
    }

    div.group-layer {
        height: 100%;
        position: absolute;
        z-index: 21;

        &.show-group-details {
            width: -webkit-fill-available;
        }
    }

    div.notification-layer {
        position: absolute;
        z-index: 41;
        width: -webkit-fill-available;
    }

    div.spinner {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 51;
    }
}
