.Segmented {
    display: flex;
    flex-direction: row;
    align-content: flex-start;

    .Segment {
        box-sizing: border-box;
        height: 30px;
        padding: 0px 0px 7px 0px;
        opacity: 0.75;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
        font-size: 14px;
        font-weight: bold;
        white-space: nowrap;
        cursor: pointer;

        &.Active {
            opacity: 0.5;
        }

        &.Selected {
            padding: 0px 0px 5px 0px;
            border-bottom: 2px solid white;
        }
    }
}