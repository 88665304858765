div.group-table-filter {
    div.selected-filter {
        width: 110px;
        height: 40px;
        border-radius: 8px;
        background-color: #194054;
        display: flex;
        flex-direction: row;
        cursor: pointer;
    }

    div.filter-area {
        &.hidden {
            display: none;
        }

        margin-top: 7px;
        width: 110px;
        height: 160px;
        border-radius: 8px;
        background-color: #194054;
        z-index: 22;
        cursor: pointer;
    }

    div.filter-text {
        width: 68px;
        height: 16px;
        margin: 12px 0px 12px 12px;
        font-family: Nunito;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    div.icon {
        width: 18px;
        height: 18px;
        margin: 11px 0px 11px 0px;
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: center;

        &.arrow-down-light {
            background-image: url('../img/icon-arrow-down-light.png');
        }
    }
}

div.group-table-filter.theme-leaderboard {
    div.selected-filter,
    div.filter-area {
        background-color: #38393c;
    }
}
