div.group-selection {
    div.icon {
        display: inline-block;
        margin: 4px 0px 4px 16px;
        width: 32px;
        height: 32px;
        background-size: 32px 32px;
        background-repeat: no-repeat;
        background-position: center;

        &.group-icon {
            background-image: url('../img/icon-groups-dark.png');
        }

        &.arrow-down-dark {
            right: 20px;
            margin: 8px 0px 8px 16px;
            width: 20px;
            height: 20px;
            background-size: 20px 20px;

            background-image: url('../img/icon-arrow-down-dark.png');
        }

        &.checked-dark {
            background-image: url('../img/icon-checked.png');
        }
    }

    div.name {
        display: inline-block;
        width: 175px;
        height: 32px;
        margin: 4px 0px 4px 8px;

        font-family: Nunito;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #18181c;
    }

    div.team-display-area {
        display: flex;
        flex-direction: column;

        width: 287px;
        margin-bottom: 8px;
        border-radius: 8px;
        background-color: #ffffff;

        max-height: 663px;
        overflow: auto;
    }

    div.team-display {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        width: 287px;
        height: 40px;
        background-color: #ffffff;

        &:first-of-type {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-of-type {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &:hover {
            background-color: #ebebeb;
        }

        div.name {
            line-height: 32px;
        }
    }
}
