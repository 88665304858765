div.settings {
    padding: 10pt;
    border-radius: 6px;
    border-width: 1px;
    border-color: gray;
    border-style: solid;
    max-width: 500px;
    margin: 10px;

    &.level-0 > span.heading {
        font-size: 200%;
    }

    &.level-1 > span.heading {
        font-size: 150%;
    }
}
