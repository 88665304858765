div.group-table-header {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 40px;
    max-width: 926px;
    margin: 25px 40px 0px 40px;
    z-index: 1;

    div.group-metadata {
        display: flex;
        flex-direction: column;
        min-width: 120px;
        margin-right: 32px;

        div.group-name {
            height: 22px;
            font-family: Nunito;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
        }

        div.group-members {
            height: 16px;
            opacity: 0.6;
            font-family: Nunito;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
        }
    }
}
