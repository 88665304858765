.MinAspectArea {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.BackgroundColor {
        background-color: #0072a8;
    }

    .FullContent {
        position: absolute;
        top: 0;
        left: 0;
        align-items: center;
        justify-content: center;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        margin: 0;
    }

    .Content {
        position: absolute;
        align-items: center;
        justify-content: center;
        min-width: 778px;
        max-width: 1200px;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        padding: 63px;
        box-sizing: border-box;
        min-width: calc(778px + 63px + 63px);
    }

    .DefaultBackground {
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: bottom right;
    }

    &.TreadMill {
        .DefaultBackground {
            background-image: url('../img/home-hero-jog.png');
            background-size: 624px 385px;
        }

        .SummaryBackground {
            background-image: url('../img/summary-hero-jog.png');
        }
    }

    &.JumpRope {
        .DefaultBackground {
            background-image: url('../img/home-hero-jump-rope.png');
            background-size: 600px 480px;
        }

        .SummaryBackground {
            background-image: url('../img/summary-hero-jump-rope.png');
        }
    }
    &.JumpingJack {
        .DefaultBackground {
            background-image: url('../img/home-hero-jumping-jack.png');
            background-size: 600px 480px;
        }

        .SummaryBackground {
            background-image: url('../img/summary-hero-jumping-jack.png');
        }
    }

    .SummaryBackground {
        background-size: contain;
        background-repeat: no-repeat;
        background-origin: content-box;
        background-position: bottom right;
    }
}
