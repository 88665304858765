.landscape-watch {
    height: 100%;

    .landscape-only-message {
        @media (orientation: landscape) {
            display: none;
        }

        @media (orientation: portrait) {
            width: 100vw;
            height: -webkit-fill-available;

            &.centering {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                flex-direction: column;
            }

            .text {
                width: 360px;
                height: 90px;
                margin: 32px 0 0;
                font-family: Nunito;
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
            }

            .icon {
                width: 188px;
                height: 188px;
                background-image: url('../img/icons-tablet-rotate.png');
                background-repeat: no-repeat;
                background-size: 188px 188px;
            }
        }
    }
    .landscape-only {
        @media (orientation: portrait) {
            display: none;
        }
        height: 100%;
    }
}
