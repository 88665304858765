.JumpRopeSecondaryDashboard {
    display: flex;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 60px;
}

.JumpRopeSecondaryMetric {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0;

    > .value {
        font-family: Nunito;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
    }

    > .title {
        margin-left: 8px;
        opacity: 0.75;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #ffffff;
        text-transform: uppercase;
    }
}
