div.groupDetails {
    border: solid 1px rgba(247, 247, 247, 0.15);
    background-color: #00273a;
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .TabSelector {
        margin-top: 33.5px;
        padding-left: 43px;

        .Segmented {
            text-transform: uppercase;
        }

        .Spacer {
            width: 42px;
        }
    }
}
