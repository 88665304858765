div.dialog-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: -webkit-fill-available;
    z-index: 31;
}

div.dialog-background {
    .icon {
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        position: absolute;
        right: 16px;
        top: 16px;

        &.icon-close {
            cursor: pointer;
            background-image: url('../img/icon-close-black.png');
        }
    }

    &.empty {
        display: none;
    }
    &.create-group,
    &.goal-setting {
        display: flex;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        background-color: rgba(0, 0, 0, 0.5);
    }

    div.dialog-content {
        &.empty {
            display: none;
        }

        &.create-group {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 385px;
            height: 291px;
            margin: -145px 0px 0px -192px;
            border-radius: 11px;
            background-color: #ffffff;
        }

        &.goal-setting {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 11px;
            background-color: #ffffff;
            margin: auto;
        }
    }

    .new-group-dialog,
    .select-goal-dialog {
        div.title {
            margin: 32px 0 0 40px;
            width: 305px;
            height: 29px;
            font-family: Nunito;
            font-size: 26px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.12;
            letter-spacing: normal;
            color: #626262;
        }

        div.new-group-name {
            margin: 38px 40px 0 42px;
            opacity: 0.75;

            input[type='text'] {
                width: 100%;
                height: 23px;
                font-family: Nunito;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #000000;
                border: none;
                border-bottom: 1px solid black;
            }
            input[type='text']::placeholder {
                opacity: 0.75;
                font-size: 14px;
                color: #bebebe;
            }
        }

        div.description {
            margin: 16px 40px 0 40px;
            width: auto;
            height: 34px;
            font-family: Nunito;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.42;
            letter-spacing: normal;
            color: #858585;
        }

        .row-button-area {
            margin: 44px 0 0 40px;
            display: flex;
            flex-direction: row;
        }

        .column-button-area {
            margin: 44px 0 0 40px;
            display: flex;
            flex-direction: column;
        }

        div.button {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            width: 105px;
            height: 40px;
            border-radius: 20px;

            font-family: Nunito;
            font-size: 13px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;

            border: solid 1.5px #18181c;

            &.back {
                margin: 0 16px 0 0;
                opacity: 0.45;

                span {
                    width: 100%;
                    display: block;
                    color: #18181c;
                    margin-top: 11px;
                }
            }

            &.next {
                margin: 0 16px 0 0;
                background-color: #18181c;

                span {
                    display: block;
                    color: #ffffff;
                    margin-top: 11px;
                    opacity: 1;
                    width: 100%;

                    &.disabled {
                        opacity: 0.2;
                    }
                }
            }

            &.confirmTarget {
                margin: 0 16px 0 0;
                background-color: #18181c;
                width: 158px;

                span {
                    margin-top: 11px;
                    width: 100%;
                    display: block;
                    font-family: Nunito;
                    font-size: 13px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 1px;
                    text-align: center;
                    color: #ffffff;
                }

                &.disabled {
                    cursor: no-drop;
                    opacity: 0.2;
                }
            }

            .icon {
                position: initial;
                width: 32px;
                height: 32px;
                margin: 4px 9px 4px 52px;
                background-repeat: no-repeat;
                background-size: 32px 32px;

                &.icon-freeform-white {
                    background-image: url('../img/icon-freeform-white.png');
                }

                &.icon-goal-white {
                    background-image: url('../img/icon-goal-white.png');
                }
            }

            &.freeForm {
                width: 305px;
                height: 40px;
                margin: 0 0 16px 0;

                span {
                    font-family: Nunito;
                    font-size: 13px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 40px;
                    letter-spacing: 1px;
                    text-align: center;
                    color: #ffffff;
                }

                background-color: #18181c;
            }

            &.targetGoal {
                width: 305px;
                height: 40px;
                margin: 0 0 16px 0;

                span {
                    font-family: Nunito;
                    font-size: 13px;
                    font-weight: 800;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 40px;
                    letter-spacing: 1px;
                    text-align: center;
                    color: #ffffff;
                }

                background-color: #18181c;
            }
        }
    }

    .select-goal-dialog {
        width: 552px;

        .calories-form {
            margin: 0 40px 33px 40px;

            input[type='text'] {
                font-family: Nunito;
                font-size: 64px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: black;

                width: 472px;
                height: 87px;
                margin: 17px 40px 0 0;

                border: none;
                border-bottom: 1px solid #979797;
                background-color: white;
            }
            input[type='text']::placeholder {
                opacity: 0.75;
                font-size: 14px;
                color: #bebebe;
            }

            .kcal-label {
                position: relative;
                width: 472px;
                height: 16px;
                font-family: Nunito;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 1px;
                text-align: right;
                color: #979799;
                margin-top: -20px;
            }

            .choices {
                display: flex;
                flex-direction: row;
                margin-top: 30px;

                .calories-icon {
                    cursor: pointer;
                    width: 42px;
                    height: 42px;

                    background-color: white;
                    background-repeat: no-repeat;
                    background-size: 42px 42px;

                    margin: 0 34px 0 0;

                    &.selected {
                        border-radius: 10px;
                        background-color: #f7f7f7;
                    }

                    &.strawberry {
                        background-image: url('../img/emojis-strawberry.png');
                    }
                    &.apple {
                        background-image: url('../img/emojis-apple.png');
                    }
                    &.banana {
                        background-image: url('../img/emojis-banana.png');
                    }
                    &.grape {
                        background-image: url('../img/emojis-grapes.png');
                    }
                    &.hotdog {
                        background-image: url('../img/emojis-hotdog.png');
                    }
                    &.pizza {
                        background-image: url('../img/emojis-pizza.png');
                    }
                }
            }
        }

        .goal-button-area {
            margin: 0px 0 32px 40px;
            display: flex;
            flex-direction: row;
        }

        .type-selector {
            display: flex;
            flex-direction: row;
            width: auto;
            height: 40px;
            margin: 28px 40px 29px 40px;

            .cell {
                cursor: pointer;
                box-sizing: border-box;
                flex: 0 0 106px;
                height: 40px;
                border-radius: 20px;
                margin-right: 16px;

                &:last-of-type {
                    margin-right: 0;
                }

                font-family: Nunito;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 40px;
                letter-spacing: 1px;
                text-align: center;
                color: #979799;
                border: solid 1px #dcdcdc;
                background-color: #ededed;

                &.selected {
                    border: solid 2px #e8e8e9;
                    background-color: #18181c;
                    color: #ffffff;
                }
            }
        }

        .goal-table {
            width: auto;
            height: auto;
            margin: 11px 40px 62px 40px;

            .goal-row {
                display: flex;
                flex-direction: row;
                margin: 16px 0 16px 0;
                height: 40px;

                &:first-of-type {
                    margin-top: 31px;
                }
            }

            .goal-cell {
                cursor: pointer;
                margin: 0 17px 0 0;
                width: 228px;
                height: 40px;
                font-family: Nunito;
                font-size: 13px;
                font-weight: 800;
                font-stretch: normal;
                font-style: normal;
                line-height: 40px;
                letter-spacing: 1px;
                text-align: center;

                border-radius: 20px;
                color: #979799;
                border: solid 1px #dcdcdc;
                background-color: #ededed;

                &.selected {
                    color: white;
                    background-color: #18181c;
                }
            }
        }
    }
}
