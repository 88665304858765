div.ProgressRing {
    overflow: hidden;
    width: 140px;
    height: 140px;
    transform: scale(1.09); // from 140x140 to 152x152

    svg {
        width: 100%;
        height: 100%;
    }

    path {
        stroke-linecap: round;
        stroke-width: 12;
    }

    path.grey {
        stroke: #061b20;
    }

    path.colored {
        stroke-dasharray: calc(63 * 3.142 * 2);
    }
}
