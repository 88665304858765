div.Container {
    background-color: #0e0e0e;
    padding-top: 32px;
    padding-left: 63px;
    padding-bottom: 54px;

    div.Links a {
        font-family: Nunito;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-decoration: none;
    }

    div.Notes {
        margin-top: 24px;

        span {
            font-family: Nunito;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.5);
        }

        a {
            margin-left: 24px;
            font-family: Nunito;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(255, 255, 255, 0.5);
            text-decoration: none;
        }
    }
}
