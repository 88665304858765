div.notification {
    &.clipboard {
        position: absolute;
        left: 50%;
        margin-left: -120px;
        width: 241px;
        height: 40px;
        margin-top: -48px;
        opacity: 0.8;
        border-radius: 10px;
        background-color: #000000;

        div.content {
            font-family: Nunito;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-top: 10px;
        }

        &.clipboard-animate-enter {
            animation-name: moveIn;
            animation-duration: 0.25s;
            animation-timing-function: ease-in;
            animation-delay: 0s;
            animation-fill-mode: forwards;

            @keyframes moveIn {
                0% {
                    margin-top: -48px;
                }
                100% {
                    margin-top: 48px;
                }
            }
        }

        &.clipboard-animate-leave {
            animation-name: moveOut;
            animation-duration: 0.3s;
            animation-timing-function: ease-out;
            animation-delay: 0s;
            animation-fill-mode: forwards;

            @keyframes moveOut {
                0% {
                    margin-top: 48px;
                }
                100% {
                    margin-top: -48px;
                }
            }
        }
    }

    &.empty {
        display: none;
    }
}
