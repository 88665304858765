div.MainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 700px;

    div.ContentContainer {
        flex-grow: 1;

        .MinAspectArea .Content {
            position: unset;
        }
    }
}
