div.leaderboard-body {
    flex: 0 1 auto;
    width: 247px;
    margin: 0 auto;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 12px;
        padding-bottom: 12px;

        &.highlight {
            border-radius: 11px;
            background-color: rgba(104, 104, 104, 0.3);
        }

        :first-child {
            margin-left: 16px;
        }

        :last-child {
            margin-right: 16px;
        }
    }

    .rank {
        width: 40px;
        font-family: Nunito;
        font-size: 12.6px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.32px;
        color: #ffffff;
    }

    .pic {
        width: 40px;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 26px;
        background-position: center;
        background-size: 100% 100%;
        transition: background-image 0.5s ease-in;

        &.online::after {
            content: '';
            display: block;
            border-color: rgb(214, 255, 175);
            border-width: 1px;
            border-style: solid;
            border-radius: 6px;
            width: 10px;
            height: 10px;
            background-color: rgb(109, 212, 0);
        }
    }

    .name {
        width: 77px;
        margin-left: 8px;
        margin-right: 8px;
        font-family: Nunito;
        font-size: 12.6px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.32px;
        color: #ffffff;
    }

    .score {
        width: 46px;
        font-family: Nunito;
        font-size: 19.8px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.5px;
        color: #ffffff;
        text-align: right;
    }
}
