div.group-page {
    display: flex;
    flex-direction: row;
    height: 100%;

    div.group-loading-spinner {
        top: 50%;
        width: -webkit-fill-available;
        position: relative;
        text-align: center;
    }
}
