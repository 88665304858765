.Button {
    height: 40px;
    border-radius: 20px;
    border: none;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 1.34px;
    color: black;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    &.Active,
    &:active {
        opacity: 0.5;
    }
}
