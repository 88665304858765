.table-layer {
    overflow: auto;
    margin-top: 37px;
}

table {
    padding: 0 40px 0 40px;
}

.group-table-body {
    display: flex;
    flex-direction: column;
    margin: 19px 0px 10px 0px;
    background-color: #00273a;
    overflow: auto;

    .row:nth-of-type(odd) {
        background-color: #0b3347;
    }

    span {
        margin-left: 20px;
    }

    .row {
        display: flex;
        flex-direction: row;
        margin: 0;
        height: 55px;
        border-radius: 8px;

        & .cell {
            flex-grow: 1;
            overflow: hidden;
            margin: auto;
            width: 113px;
            font-family: Nunito;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }

        & .RANK {
            height: 19px;
            font-size: 14px;
            margin: 18px 0px 18px 0px;
        }

        & .AVATAR {
            flex-grow: 0;
            width: 32px;
            height: 32px;
            margin: 11px 8px 11px 16px;
            background-size: 32px 32px;
            background-repeat: no-repeat;
            border-radius: 50%;
        }

        & .NAME {
            width: 57px;
            height: 32px;
            font-size: 11px;
            text-align: left;
            margin: 11px 0px 11px 0px;
        }

        & .CALORIES,
        & .DISTANCE,
        & .TIME,
        & .OUTPUT,
        & .PACE,
        & .SPEED,
        & .STEP,
        & .JUMP,
        & .COMBO {
            height: 23px;
            font-size: 17px;
            font-weight: bold;
            margin: 16px 0px 16px 0px;
        }
    }

    & .data-window {
        height: 634px;
    }
}
