div.basic-dashboard {
    @media (max-width: 1200px) {
        width: 180px;
        height: 117px;
    }
    @media (min-width: 1200px) and (max-width: 1440px) {
        width: 218px;
        height: 142px;
    }
    @media (min-width: 1440px) {
        width: 232px;
        height: 151px;
    }
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    justify-content: center;

    .metric-title {
        @media (max-width: 1200px) {
            height: 19px;
            font-size: 14px;
            letter-spacing: 1px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            height: 23px;
            font-size: 17px;
            letter-spacing: 1.21px;
        }
        @media (min-width: 1440px) {
            height: 24px;
            font-size: 18px;
            letter-spacing: 1.28px;
        }
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        color: #ffffff;
        text-align: center;
        opacity: 0.75;
    }

    .metric-value {
        @media (max-width: 1200px) {
            height: 64px;
            font-size: 52px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            height: 77.6px;
            font-size: 63px;
        }
        @media (min-width: 1440px) {
            height: 82.3px;
            font-size: 66.9px;
        }
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: center;
    }
}

div.computed-dashboard {
    @media (max-width: 1200px) {
        width: 660px;
        height: 200px;
    }
    @media (min-width: 1200px) and (max-width: 1440px) {
        width: 800px;
        height: 242px;
    }
    @media (min-width: 1440px) {
        width: 849px;
        height: 257px;
    }
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .container {
        width: 215px;

        display: flex;
        flex-direction: column;

        .metric-title {
            @media (max-width: 1200px) {
                height: 19px;
                font-size: 14px;
                letter-spacing: 1px;
            }
            @media (min-width: 1200px) and (max-width: 1440px) {
                height: 23px;
                font-size: 16.9px;
                letter-spacing: 1.21px;
            }
            @media (min-width: 1440px) {
                height: 24px;
                font-size: 17.9px;
                letter-spacing: 1.28px;
            }
            font-family: Nunito;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
            opacity: 0.75;
        }

        .value {
            @media (max-width: 1200px) {
                font-size: 48px;
            }
            @media (min-width: 1200px) and (max-width: 1440px) {
                font-size: 58.2px;
            }
            @media (min-width: 1440px) {
                font-size: 61.7px;
            }
            font-family: Nunito;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
        }

        .details-container {
            display: flex;
            flex-direction: row;

            .details {
                display: flex;
                flex-direction: column;
                position: relative;

                &:first-of-type {
                    border-right: solid 1px #979797;
                }

                .title {
                    @media (max-width: 1200px) {
                        height: 19px;
                        font-size: 14px;
                        letter-spacing: 1px;
                    }
                    @media (min-width: 1200px) and (max-width: 1440px) {
                        height: 23px;
                        font-size: 16.9px;
                        letter-spacing: 1.21px;
                    }
                    @media (min-width: 1440px) {
                        height: 24px;
                        font-size: 17.9px;
                        letter-spacing: 1.28px;
                    }
                    font-family: Nunito;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    text-align: center;
                    color: #ffffff;
                }
                .value {
                    @media (max-width: 1200px) {
                        height: 32px;
                        font-size: 24px;
                    }
                    @media (min-width: 1200px) and (max-width: 1440px) {
                        // width: 130px;
                        height: 39px;
                        font-size: 29px;
                    }
                    @media (min-width: 1440px) {
                        // width: 138px;
                        height: 42px;
                        font-size: 30.8px;
                    }
                    width: 107px;
                    font-family: Nunito;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #ffffff;
                }

                .icon {
                    @media (max-width: 1200px) {
                        bottom: 32px;
                        right: 14px;
                    }
                    @media (min-width: 1200px) and (max-width: 1440px) {
                        bottom: 41px;
                        right: 12px;
                    }
                    @media (min-width: 1440px) {
                        bottom: 44px;
                        right: 10px;
                    }
                    position: absolute;
                    bottom: 2px;
                    right: 8px;
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: 20px 20px;

                    &.icon-up-green {
                        background-image: url('../img/icon-up-green.png');
                    }

                    &.icon-down-red {
                        background-image: url('../img/icon-down-red.png');
                    }
                }
            }
        }
    }
}
