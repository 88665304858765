.header {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .row {
        display: flex;
    }

    .header-cell {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        width: 140px;
        height: 32px;
        border-radius: 8px;

        .header-content {
            flex-grow: 1;
            font-family: Nunito;
            font-size: 9px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: left;
            color: #ffffff;
            margin: 0px 0px 0px 20px;

            &.oneRow {
                margin-top: 10px;
            }

            &.twoRow {
                margin-top: 4px;
            }
        }

        .header-icon {
            flex-grow: 1;
            width: 12px;
            height: 12px;
            margin-top: 10px;
            margin-right: 20px;
            background-size: 12px 12px;
            background-repeat: no-repeat;
            background-position: center;

            &.ascending {
                background-image: url('../img/icon-arrow-up-light.png');
            }

            &.descending {
                background-image: url('../img/icon-arrow-down-light.png');
            }
        }

        &.selected {
            background-color: #0b3347;
        }

        &.not-selected {
            background-color: #00273a;

            .header-icon {
                display: none;
            }
        }
    }
}
