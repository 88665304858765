.SummaryStats {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: stretch;
}

.SummaryStats > div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    align-items: flex-end;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 19px;
    padding-bottom: 22px;
}

.SummaryStats .Spacer {
    width: 34px;
}

.SummaryStats .Metric {
    height: 50px;
}
