div.LeaderboardHeader {
    top: 0;
    right: 0;
    margin: 48px 28px 0px 28px;

    div.leaderboard-filter-row {
        max-height: 40px;
        position: relative;
    }

    div.row {
        display: flex;
        flex-direction: row;
    }

    div.leaderboard-title {
        width: 110px;
        height: 40px;

        span.content {
            font-family: Nunito;
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: #ffffff;
        }
    }

    div.leaderboard-header {
        margin-top: 21px;
        padding-bottom: 7px;
        border-bottom: solid 1px rgba(255, 255, 255, 0.32);

        .rank-label {
            width: 110px;
            height: 12px;
            font-family: Nunito;
            font-size: 9px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 12px;
            letter-spacing: 1px;
            color: #ffffff;
        }

        .kcal-label {
            width: 110px;
            height: 12px;
            font-family: Nunito;
            font-size: 9px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 12px;
            letter-spacing: 1px;
            text-align: right;
            color: #ffffff;
        }
    }
}
