div.ballon {
    position: absolute;
    width: 257px;
    height: 138px;
    border-radius: 10px;
    background-color: #ffffff;

    &.hide {
        display: none;
    }

    div.triangle {
        position: absolute;
        width: 0;
        height: 0;

        &.north {
            top: -10px;
            right: 20px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white;
        }
        &.south {
        }
        &.east {
        }
        &.west {
            margin-top: 10px;
            margin-left: -10px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid white;
        }
    }

    div.title {
        width: 209px;
        height: 48px;
        margin: 24px 24px 0px 24px;
        font-family: Nunito;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #18181c;
    }

    div.content {
        width: 209px;
        height: 34px;
        margin: 10px 24px 0px 24px;
        opacity: 0.7;
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #18181c;
    }
}

div.ballon-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
}
