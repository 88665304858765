.PageInfoBox {
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    > .Title {
        height: 39px;
        font-size: 20px;
        font-weight: 600;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        line-height: 1.95;
        white-space: nowrap;
    }
}
