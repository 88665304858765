.SideBarProfileButton {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #f7f7f7;
    margin-bottom: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .Monogram {
        font-family: Nunito;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.9px;
        text-align: center;
        color: #18181c;
        text-transform: uppercase;
    }

    .OnlineDot {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #6dd400;
        position: absolute;
        bottom: 0px;
        right: 0px;
    }
}
