div.GoalDashboard {
    @media (max-width: 1200px) {
        width: 240px;
        height: 264px;
    }
    @media (min-width: 1200px) and (max-width: 1440px) {
        width: 291px;
        height: 320px;
    }
    @media (min-width: 1440px) {
        width: 309px;
        height: 340px;
    }
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-flow: column;

    div.BottomContainer {
        flex-grow: 1;
        position: relative;

        div.ProgressRing {
            @media (max-width: 1200px) {
                width: 140px;
                height: 140px;
            }
            @media (min-width: 1200px) and (max-width: 1440px) {
                width: 170px;
                height: 170px;
            }
            @media (min-width: 1440px) {
                width: 180px;
                height: 180px;
            }
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        div.RingLabelsContainer {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -60%);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    div.TopContainer {
        @media (max-width: 1200px) {
            margin-top: 17px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            margin-top: 21px;
        }
        @media (min-width: 1440px) {
            margin-top: 22px;
        }
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .percentage-label {
        @media (max-width: 1200px) {
            font-size: 36px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            font-size: 43.4px;
        }
        @media (min-width: 1440px) {
            font-size: 46.2px;
        }
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
    }

    .completed-label {
        @media (max-width: 1200px) {
            font-size: 14px;
            letter-spacing: 1px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            font-size: 16.9px;
            letter-spacing: 1.21px;
        }
        @media (min-width: 1440px) {
            font-size: 17.9px;
            letter-spacing: 1.28px;
        }
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        text-align: center;
        color: #b6b6b6;
    }

    .your-goal-label {
        @media (max-width: 1200px) {
            height: 19px;
            font-size: 14px;
            letter-spacing: 1px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            height: 23px;
            font-size: 16.9px;
            letter-spacing: 1.21px;
        }
        @media (min-width: 1440px) {
            height: 24px;
            font-size: 17.9px;
            letter-spacing: 1.28px;
        }
        font-family: Nunito;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 1px;
        color: #b6b6b6;
    }

    .goal-value {
        @media (max-width: 1200px) {
            height: 38px;
            font-size: 28px;
        }
        @media (min-width: 1200px) and (max-width: 1440px) {
            height: 59px;
            font-size: 43.4px;
        }
        @media (min-width: 1440px) {
            height: 63px;
            font-size: 46.2px;
        }
        font-family: Nunito;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #ffffff;
    }
}
