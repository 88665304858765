div.group-list {
    display: flex;
    flex-direction: column;
    width: 280px;
    flex: 0 0 280px;
    height: 100%;
    border: solid 1px rgba(247, 247, 247, 0.15);
    background-color: #00273a;

    .scrollable-area {
        height: auto;
        overflow-y: scroll;
        margin-top: 32px;
    }

    .separator {
        width: 100%;
        height: 0px;
        margin-top: 24px;
        border-bottom: solid 1px rgba(247, 247, 247, 0.15);
    }

    .button-create-group {
        margin: 29px 24px 0 24px;
    }

    div.title-text {
        width: 232px;
        height: 24px;
        opacity: 0.75;
        font-family: Nunito;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0px 0px 16px 24px;
    }

    div.no-data {
        width: 232px;
        height: 51px;
        margin: 16px 24px 8px 24px;
        font-family: Nunito;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #858585;
    }

    div.group-list-header {
        display: flex;
        flex-direction: column;
        flex: 0 0 146px;

        .title-area {
            display: flex;
            flex-direction: row;
        }

        .search-icon {
            width: 18px;
            height: 18px;
            margin: 7px 0px 7px 16px;
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../img/icons-search.png');
        }

        div.icon {
            width: 40px;
            height: 40px;
            margin: 25px 0px 0px 24px;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            background-position: center;

            &.group-icon {
                background-image: url('../img/icon-groups.png');
            }
        }

        div.text {
            width: 204px;
            height: 39px;
            font-family: Nunito;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.63;
            letter-spacing: normal;
            color: #ffffff;
            margin: 26px 0px 0px 12px;
        }

        .search-input-layer {
            width: 232px;
            height: 32px;
            margin: 33px 24px 0px 24px;
            border-radius: 20px;
            border: solid 1.3px #c9c9ca;
            display: flex;
            flex-direction: row;
        }

        input {
            border: 0;
            background-color: #00273a;
            margin: 8px;
            width: auto;
            height: 16px;
            font-family: Nunito;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
        }
    }

    div.group-list-view {
        display: flex;
        flex-direction: column;

        &.joined {
            > .title-text {
                margin-top: 24px;
            }
        }

        div.group-row {
            width: 280px;
            height: 60px;
            display: flex;
            flex-direction: row;
            cursor: pointer;

            div.group-metadata {
                display: flex;
                flex-direction: column;
                width: 152px;
                margin: 11px 0px 0px 24px;

                div.group-name {
                    height: 22px;
                    overflow: hidden;
                    font-family: Nunito;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                }

                div.group-member-count {
                    opacity: 0.8;
                    font-family: Nunito;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #ffffff;
                }
            }

            div.invite-button {
                margin: 14px 0px 0px 0px;
            }

            &.selected {
                background-color: #194054;
            }
        }

        div.create-group-button {
            margin: 24px 24px 32px 24px;
        }
    }
}
