.Activity {
    position: fixed;
    width: 100vw;

    // trigger the use of device's GPU
    // Ref:
    // * https://stackoverflow.com/a/33875662
    // * https://www.smashingmagazine.com/2016/12/gpu-animation-doing-it-right/

    // -webkit-backface-visibility: hidden;
    // -webkit-perspective: 1000;
    // -webkit-transform: translate3d(0, 0, 0);
    // -webkit-transform: translateZ(0);
    // backface-visibility: hidden;
    // perspective: 1000;
    // transform: translate3d(0, 0, 0);
    // transform: translateZ(0);

    .blurMask {
        position: absolute;
        filter: blur(30px);
        background-color: black;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }

    div.GameLayer {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    div.UILayer {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
    }

    div.buttonPanel {
        display: flex;
        margin-left: 24px;
        margin-top: 27px;

        .pauseButton {
            width: 43px;
            height: 43px;
            background-image: url('./images/pause-button.svg');
            background-repeat: no-repeat;
            margin-right: 25px;
            &.Active {
                opacity: 0.5;
            }
        }
        .soundButton {
            width: 43px;
            height: 43px;
            background-repeat: no-repeat;
            background-size: 43px 43px;

            &.enabled {
                background-image: url('../../img/icons-speaker-circle.svg');
            }

            &.disabled {
                background-image: url('../../img/icons-mute-circle.svg');
            }
        }

        .resumeButton {
            width: 129px;
            margin-right: 24px;
        }
        .endButton {
            width: 107px;
            background-color: #e02020;
            color: #fff;
            margin-right: 24px;
        }

        &.running {
            .resumeButton {
                display: none;
            }
        }

        &.paused {
            .pauseButton {
                display: none;
            }
        }
    }

    div.UILayerLeftContainer {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        position: relative;

        div.group-metadata {
            position: absolute;
            top: 32px;
            right: 32px;
            text-align: right;

            .name {
                height: 24px;
                font-family: Nunito;
                font-size: 18px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 22px;
                letter-spacing: normal;
                color: #ffffff;
            }
            .member-count {
                height: 19px;
                opacity: 0.6;
                font-family: Nunito;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 16px;
                letter-spacing: normal;
                color: #ffffff;
            }
        }

        div.UILayerLeftBottomContainer {
            flex-grow: 1;
            margin-top: 38px;
            display: flex;
            justify-content: center;

            div.dashboardContainer {
                display: flex;
                flex-direction: column;

                div.upperContainer {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 36px;

                    div.basicMetricsContainer {
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                    }
                }
            }
        }
    }
}

.Inactive {
    .HideWhenInactive {
        // visibility: hidden;
    }
}

.blurBg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.45;
    background-image: url('../../img/blur-bg.jpg');
}
