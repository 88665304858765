.HomeActivitySelector {
    margin-top: 33.5px;

    .Segmented {
        text-transform: uppercase;
    }

    .Spacer {
        width: 42px;
    }
}
