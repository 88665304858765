@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');

body {
    margin: 0;
    min-width: 100vw;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-width: -webkit-fill-available;
    min-height: -webkit-fill-available;
    background-color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
}

* {
    font-family: 'Nunito', sans-serif;
    color: white;
}

input,
textarea {
    outline: none;
}
