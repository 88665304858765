div.showPerformancePanel div.driver-container div.performance {
    display: block;
}

div.performance {
    font-family: 'Verdana';
    font-size: 20px;
    color: #0f0;
    padding: 10px;
    background: rgba(40, 40, 40, 0.5);
    border-radius: 10px;
    position: absolute;
    height: 30px;
    width: 600px;
    left: 50%;
    margin-left: -300px;

    // Hide this for now, since we don't want to show this to our users.
    display: none;

    div {
        display: inline-block;
        height: 30px;
        width: 200px;
        span.fast {
            color: #3f3;
        }
        span.normal {
            color: #88f;
        }
        span.slow {
            color: #f22;
        }
        .game-engine {
            color: #2bb;
        }
        .prediction-time {
            color: #b2b;
        }
        .prediction-fps {
            color: #bb2;
        }
    }
}
