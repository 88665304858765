div.sidebar {
    width: 120px;
    flex: 0 0 120px;
    height: 100%;

    display: flex;
    flex-direction: column;
    border: solid 1px rgba(247, 247, 247, 0.15);
    background-color: #00273a;
    align-items: center;

    div.button {
        width: 84px;
        height: 60px;
        display: block;
        cursor: pointer;
        margin: 0px 18px 0px 18px;

        &.selected {
            border-radius: 9px;
            background-color: #194054;
        }

        &.not-selected {
            opacity: 0.3;
        }

        div.icon {
            display: inline-block;
            width: 84px;
            height: 32px;
            font-family: Nunito;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-top: 7px;
            background-size: 32px 32px;
            background-repeat: no-repeat;
            background-position: center;
        }

        div.text-label {
            width: 84px;
            height: 18px;
            font-family: Nunito;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            margin-top: -8px;
        }

        &.home > div.icon {
            background-image: url('../img/icon-home.png');
        }

        &.groups > div.icon {
            background-image: url('../img/icon-groups.png');
        }

        &.history > div.icon {
            background-image: url('../img/icon-groups-history.png');
        }
    }

    & div.button:first-child {
        margin-top: 32px;
    }

    div.separator {
        width: 84px;
        height: 32px;
    }

    .Spacer {
        flex-grow: 1;
    }

    .SideBarProfilePanelLayer > .Panel {
        position: absolute;
        bottom: 33px;
        left: 110px;
    }
}
