.Metric {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-end;
}

.Metric .TitleRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.Metric.Big .TitleRow {
    margin-bottom: 14px;
}

.Metric.Small .TitleRow {
    margin-bottom: 1px;
}

.Metric .Title {
    opacity: 0.75;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
    font-weight: bold;
}

.Metric.Big .Title {
    font-size: 14px;
    letter-spacing: 1.25px;
}

.Metric.Small .Title {
    font-size: 12px;
    letter-spacing: 1.07px;
}

.Metric .ValueRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.Metric.Big .Value {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 56px;
    line-height: 0.76;
    letter-spacing: -0.51px;
    margin-inline-start: -4px;
}

.Metric.Small .Value {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.35);
    font-size: 28px;
    letter-spacing: -0.71px;
    line-height: 0.85;
}

.Metric .Unit {
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.26);
    font-weight: bold;
    white-space: pre-line;
}

.Metric.Big .Unit {
    font-size: 11.2px;
    line-height: 1.16;
    margin-inline-start: 7px;
    margin-bottom: 3px;
}

.Metric.Small .Unit {
    font-size: 11.2px;
    line-height: 1.16;
    margin-inline-start: 5px;
    margin-bottom: 1px;
}
