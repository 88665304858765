.SummaryMode .Segmented .Spacer {
    width: 34px;
}

div.MainContainer {
    display: flex;
    flex-direction: column;
    min-height: 700px;

    div.ContentContainer {
        flex-grow: 1;
    }
}
